@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.tabs {
	display: flex;
	list-style: none;
	width: 100%;
	margin: 0;

	align-items: center;
	justify-content: space-around;

	text-align: center;

	border-bottom: 1px solid #a0a0a0;

	position: relative;

	//contain: paint;

	&::after {
		content: '';
		position: absolute;
		bottom: -1px;
		left: calc(var(--selected-tab) * calc(100% / var(--num-tabs)));
		width: calc(100% / var(--num-tabs));
		height: 2px;
		background-color: $black;

		transform: translateY(0.5px);

		transition: all 0.2s ease-out;
	}
}

.tabs li {
	flex: 1;

	button {
		width: 100%;
		padding: 0.75rem;
	}

	opacity: 0.5;

	&:hover {
		opacity: 0.9;
	}

	&[data-selected='true'] {
		opacity: 1;
	}

	transition: opacity 0.2s ease-out;
}
