@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.section {
	@include section-padding;

	background: linear-gradient(117deg, rgba(0, 0, 0, 0) 8.3%, rgba(0, 0, 0, 0.02) 87.56%), $background-off-white;
}

.container {
	display: flex;
	max-width: 50rem;
	margin-inline: auto;

	padding: 4rem 2.5rem;

	@include xs-only {
		padding: 2rem 1rem;
	}

	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 2.5rem;

	text-align: center;

	border-radius: $br-sm;
	background: $background-white;
	color: $text-black;
	box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.02);
}

.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2.5rem;

	@include xs-only {
		gap: 1.5rem;
	}
}

.content h2 {
	text-wrap: balance;
	margin-inline: auto;
}

.tabs_container {
	width: 100%;
	@include xs-only {
		display: none;
	}
}

.dropdown_container {
	width: 100%;
	@include sm-up {
		display: none;
	}
}

.big_numbers {
	padding: 1.25rem 0;
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	gap: clamp(3rem, 6vw, 7rem);

	@include xs-only {
		padding: 1.75rem 0 1.25rem;
	}
	position: relative;
}

.divider {
	width: 1px;
	background-color: #dbdbdb;

	position: absolute;
	left: 50%;
	top: 1.25rem;
	bottom: 1.25rem;
	transform: translateX(-50%);
}

.big_number_item {
	flex: 1;

	p {
		color: $text-grey-light;
		margin-block-start: 0.25rem;
	}
}

.description_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1.5rem;
}
